import React, { useEffect, useState } from 'react';
import styled from '../../styles/styled-components';
import ThemeInterface from '../../styles/themeDefinition';

interface StyledProps {
  isOpen: boolean;
  theme: ThemeInterface;
}

const CollapsibleStyled = styled.div`
  border-bottom: 2px solid ${(props: any) => props.theme.gray100};
  padding-bottom: 18px;
  margin: 0 34px;
`;
const Title = styled.h2`
  position: relative;
  padding-right: 45px;
  color: ${(props: StyledProps) =>
    props.isOpen ? props.theme.primary600 : props.theme.gray500};
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
`;
const Icon = styled.i`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid
    ${(props: StyledProps) =>
      props.isOpen ? props.theme.primary600 : props.theme.gray500};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: ' ';
    position: absolute;
    width: ${(props: StyledProps) => (props.isOpen ? '0' : '16px')};
    height: 2px;
    background-color: ${(props: StyledProps) =>
      props.isOpen ? props.theme.primary600 : props.theme.gray500};
    transform: rotate(
      ${(props: StyledProps) => (props.isOpen ? '90deg' : '0deg')}
    );
    transition: transform 0.25s ease, width 0.25s ease-out;
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 2px;
    height: 16px;
    background-color: ${(props: StyledProps) =>
      props.isOpen ? props.theme.primary600 : props.theme.gray500};
    transform: rotate(
      ${(props: StyledProps) => (props.isOpen ? '90deg' : '0deg')}
    );
    transition: all 0.25s ease;
  }
`;
const Content = styled.div`
  max-height: ${(props: StyledProps) => (props.isOpen ? '100%' : '0')};
  opacity: ${(props: StyledProps) => (props.isOpen ? '1' : '0')};
  overflow: hidden;
  font-family: Muli;
  line-height: 1.5;
  img {
    max-width: 100%;
  }
  ${(props: StyledProps) =>
    props.isOpen
      ? 'transition: all 0.3s ease;'
      : 'transition: all 0.3s ease 0.2s;'}
`;

export interface Props {
  title: string;
  children: object;
  opened: boolean;
}

const Collapsible: React.FC<Props> = ({ title, children, opened }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  return (
    <CollapsibleStyled>
      <Title
        isOpen={isOpen}
        onClick={() => setIsOpen((prevValue: boolean) => !prevValue)}
      >
        {title}
        <Icon isOpen={isOpen} />
      </Title>
      <Content isOpen={isOpen}>{children}</Content>
    </CollapsibleStyled>
  );
};

export default Collapsible;

// Comment
