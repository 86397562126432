import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import showdown from 'showdown';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Collapsible from '../components/UI/Collapsible';
import ContentHeader from '../components/UI/ContentHeader';
import FakeCollapsible from '../components/UI/FakeCollapsible';
import SectionContainer from '../components/UI/SectionContainer';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';

const converter = new showdown.Converter();

// PRUEBA
const slugify = (str: string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

const goToQuestion = async (question: string, e: any) => {
  if (!window.location.hash.includes(slugify(question))) {
    window.location.hash = slugify(question);
    e.preventDefault();
  }
  await new Promise(resolve => setTimeout(resolve, 350));
};

interface PropsTemplate {
  title: string;
  subheading: string;
  image: Image | string;
  content: string;
  items: Array<{
    question: string;
    answer: string;
  }>;
  contentComponent?: Content | HTMLContent;
  collapsibleComponent?: React.ReactNode;
}

export const FaqPageTemplate: React.FC<PropsTemplate> = ({
  title,
  subheading,
  image,
  content,
  items,
  contentComponent,
  collapsibleComponent,
}) => {
  const PageContent = contentComponent || Content;
  const CollapsibleComponent = collapsibleComponent || FakeCollapsible;

  useEffect(() => {
    const setQuestion = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      window.location.hash = window.location.hash;
      window.location.href = window.location.href;
    };
    (window.location.hash ||
      (window.location.href && window.location.href.includes('#'))) &&
      setQuestion();
  }, []);

  return (
    <>
      <ContentHeader
        title={title}
        subheading={subheading}
        image={
          typeof image === 'string' ? image : image.childImageSharp.fluid.src
        }
      />
      <SectionContainer>
        <PageContent content={content} />
        {items.map(item => (
          <section
            id={slugify(item.question)}
            onClick={e => {
              goToQuestion(item.question, e);
            }}
          >
            <CollapsibleComponent
              title={item.question}
              key={item.question}
              opened={() =>
                window.location.hash.includes(slugify(item.question))
              }
            >
              <HTMLContent
                className=""
                content={converter.makeHtml(item.answer)}
              />
            </CollapsibleComponent>
          </section>
        ))}
      </SectionContainer>
    </>
  );
};

interface Props {
  html: string;
  frontmatter: {
    title: string;
    subheading: string;
    image: Image;
    items: Array<{
      question: string;
      answer: string;
    }>;
  };
}

const FaqPage: React.FC<DataMarkdown<Props>> = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <FaqPageTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        content={post.html}
        items={post.frontmatter.items}
        contentComponent={HTMLContent}
        collapsibleComponent={Collapsible}
      />
    </Layout>
  );
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        items {
          question
          answer
        }
      }
    }
  }
`;
